
export default class WorkoutsController {
  init() {
    this.enableWorkoutSorting()
    this.highlightExerciseFields()

    window.reloadWorkoutExercisesTable = this.reloadWorkoutExercisesTable.bind(this)
    window.editExercise = this.editExercise.bind(this)
  }

  enableWorkoutSorting() {
    if ($('.workout-detail .workout-exercises').length == 0) return

    $('.workout-detail .workout-exercises').sortable({
      cursor: 'move',
      handle: '.sorter',
      start: function(e, ui) {
        ui.item.addClass('active-item-shadow')
      },
      stop: function(e, ui) {
        ui.item.removeClass('active-item-shadow')
      },
      update: function(e, ui) {
        var table = $(ui.item).closest('tbody')
        var ids = []
        table.find('tr.workout_exercise').each(function(i, el) {
          ids.push($(el).data('id'))
        })

        var detail = table.closest('.workout-detail')
        var update_url = detail.data('update-url')
        var id = detail.data('id')

        $.ajax({
          type: 'POST',
          url: update_url,
          dataType: 'json',
          data: {
            ids: ids,
          },
          success: function(data) {
            if (data.success == true) {
              ui.item.effect('highlight', {}, 1000)
              this.reloadWorkoutExercisesTable(id)
            } else {
              app_notify('danger', "Errore server: #{data.error}")
            }
          }
        })

      }
    })
    $('.workout-detail .workout-exercises').disableSelection()
  }

  highlightExerciseFields() {
    $('.workout_exercise_autocomplete').bind('railsAutocomplete.select', function(evt, data) {
      evt.preventDefault()
      this.exerciseFieldsSuccess(evt.target)
    }.bind(this))
    $('.workout_exercise_autocomplete').keyup(function(evt) {
      evt.preventDefault()
      this.exerciseFieldsFailure(evt.target)
      $(evt.target).removeClass('is-valid').removeClass('is-invalid')
    }.bind(this))
    $('.workout_exercise_autocomplete').on('blur', function(evt) {
      evt.preventDefault()
      if ($(evt.target).data('valid') == true) {
        this.exerciseFieldsSuccess(evt.target)
      } else {
        this.exerciseFieldsFailure(evt.target)
      }
    }.bind(this))

    var forms = $('.workout_exercise_autocomplete').closest('form')
    var submit = forms.find('input[type=submit]')
    submit.addClass('disabled')
  }

  exerciseFieldsFailure(input) {
    $(input).data('valid', false).removeClass('is-valid').addClass('is-invalid')
    $(input).closest('form').find('input[type=submit]').addClass('disabled')
  }

  exerciseFieldsSuccess(input) {
    $(input).data('valid', true).addClass('is-valid').removeClass('is-invalid')
    $(input).closest('form').find('input[type=submit]').removeClass('disabled')
  }

  reloadWorkoutExercisesTable(id) {
    var container = $(".workout-detail[data-id=" + id + "]")
    var table = container.find(".table-workout-exercises")
    var reload_url = container.data('reload-url')
    var form = container.find('form.workout-exercise-form')
    $.ajax({
      type: 'GET',
      url: reload_url,
      dataType: 'html',
      success: function(data) {
        table.after(data)
        table.remove()
        this.enableWorkoutSorting()
        this.resetForm(form)
      }.bind(this)
    })
  }

  resetForm(form) {
    form[0].reset()
    form.closest('.table-workout-exercise-form-container').find('h2').text('Aggiungi esercizio')
    form.find("input[type=hidden][name='workout_exercise[id]']").val("")
    form.find('input[type=submit]').val('Aggiungi esercizio')
    if (form.data('original-action')) {
      form.attr('action', form.data('original-action'))
    }
  }

  editExercise(id) {
    var container = $("tr.workout_exercise[data-id=" + id + "]")
    var table = container.closest(".table-workout-exercises")
    var form_container = container.closest('.workout-detail').find('.table-workout-exercise-form-container')
    const form = form_container.find('form')
    if (!form.data('original-action')) {
      form.data('original-action', form.attr('action'))
    }

    form.attr('action', form.data('original-action') + '/' + id)

    form_container.find('h2').text('Modifica esercizio')
    form.find('input[type=submit]').val('Salva esercizio')

    var dataFields = ['exercise', 'exercise_id', 'note', 'series', 'repetitions', 'tut', 'rest_period', 'weight', 'notes']
    dataFields.forEach(function(item) {
      var input = form.find("[name='workout_exercise[" + item + "]']")
      input.val(container.data('exercise-' + (item == 'exercise_id' ? 'exercise-id' : item)))
      console.log("[name='workout_exercise[" + item + "]']", input, 'exercise-' + item, container.data('exercise-' + (item == 'exercise_id' ? 'exercise-id' : item)))
    }.bind(this))

    console.log("ciao")

    var warmup = form.find("input[type=checkbox][name='workout_exercise[warmup]']")
    if (container.data('exerciseWarmup') === true) {
      warmup.prop('checked', true)
    } else {
      warmup.prop('checked', false)
    }

    var hiddenId = form.find("input[type=hidden][name='workout_exercise[id]']")
    hiddenId.val(id)
  }
}
