export default class MedicinesController {
  constructor() {
    this.current_medicine = null
    this.medicines = []
  }

  init() {
    window.medicines_controller = this

    if ($('.medicines-container')) {
      if ($('#medicine-autocomplete')) {
        $('#medicine-autocomplete').bind('railsAutocomplete.select', function(event, data) {
          this.current_medicine = data.item
          // this.addMedicine(data.item)
        }.bind(this))
        
        $('#medicine-autocomplete').bind('railsAutocomplete.noMatch', function(event, data) {
          this.current_medicine = null
        }.bind(this))
      }
    }
  }

  addMedicine(el) {
    var newElement = {item: el}
    console.log("Adding new element", newElement)
    this.medicines.push(newElement)
    this.addMarkupForMedicine(newElement)
    this.updateForm()
    console.log("Added new element", newElement)
  }

  removeMedicine(index) {
    this.medicines = this.medicines.splice(index, 1)
    this.updateForm()
  }

  updateForm() {
    if (this.medicines.length === 0) {
      $('.medicines-container .medicines-form-submit').hide()
    } else {
      $('.medicines-container .medicines-form-submit').show()
    }

    var form = $('form#new_prescription')
    form.on('submit', function(evt) {
      evt.preventDefault()
      evt.stopImmediatePropagation()

      var inputs = $('.medicines-hidden-inputs')
      inputs.empty()
      var createInput = function(name, value) {
        return $('<input />').attr('name', 'prescription[' + name + ']').attr('type', 'hidden').val(value)
      }

      inputs.append(createInput('medicines_count', this.medicines.length))
      this.medicines.forEach(function(el, index) {
        inputs.append(createInput('medicine_' + index + '_id', this.medicines[index].item.id))
        inputs.append(createInput('medicine_' + index + '_body', this.medicines[index].editor.root.innerHTML))
      }.bind(this))

      form[0].submit()
    }.bind(this))
  }

  addMarkupForMedicine(el) {
    var container = $('<div/>').addClass('medicine-editor')
    var title = $('<h4/>').text(el.item.label)
    var editor = $('<div/>').addClass('wysiwyg-editor').append(el.item.body)
    container.append(title).append(editor)
    container.append($('<hr/>'))

    $('.medicines-list').append(container)

    var quill = new Quill(editor[0], {
      theme: 'snow',
    })

    el.editor = quill
  }
}
