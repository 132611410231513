// import $ from 'jquery/dist/jquery'
import Highcharts from 'highcharts'

class VisitsController {
  init() {
    if ($('select.compare-visit-selector').length > 0) {
      this.setup_visit_selector();
      this.setup_photo_comparer();
    }
    if ($('#measurements-graphs').length > 0) {
      this.setup_measurements_graph();
    }
  }

  setup_photo_comparer() {
    $('.compare-controls a').click(function(evt) {
      evt.preventDefault();
      evt.stopImmediatePropagation();

      this.load_big_photo(0);
      this.load_big_photo(1);

      $('#compare-photos-modal').modal();
    }.bind(this));
  }

  load_big_photo(index) {
    var photo = $(".selected-visit-container[data-index=" + index + "] .photo[data-id=" + this.selected_photos[index] + "]");
    var src = photo.find('a.image').attr('href');
    $("#compare-photos-modal img[data-index=" + index + "]").attr('src', src);
  }

  setup_visit_selector() {
    this.selected_photos = [null,null];
    $('select.compare-visit-selector').on('change', function(evt) {
      var target = $(evt.target);
      var index = target.data('index');
      var value = target.val();
      if (value && value !== '') {
        console.log("Ho sucato ", value);
        this.load_visit_photos(value, index);
      }
    }.bind(this))
  }

  load_visit_photos(visit_id, index) {
    var url = $('select.compare-visit-selector[data-index=' + index + ']').data('url').replace("__id__", visit_id);
    console.log("I will load", visit_id, 'on', index, 'accessing', url);
    $.ajax({
      url: url,
      dataType: 'html',
      success: function(data) {
        console.log("Success with", data);
        $(".selected-visit-container[data-index=" + index + ']').html(data);
        this.handle_photo_selection(index);
      }.bind(this),
      error: function(data) {
        console.error("Error with", data);
        alert("È stato impossibile caricare la visita scelta");
      }
    });
  }

  handle_photo_selection(index) {
    this.selected_photos[index] = null;
    $(".selected-visit-container[data-index=" + index + '] .photo').click(function(evt) {
      evt.stopImmediatePropagation();
      evt.preventDefault();
      var photo = $(evt.target).closest('.photo');
      console.log(photo[0]);
      var id = photo.data('id');

      this.selected_photos[index] = id;
      this.update_selected_photos(index);
    }.bind(this));
  }

  update_selected_photos(index) {
    console.log("Updating with", this.selected_photos);
    $(".selected-visit-container[data-index=" + index + "] .photo").removeClass('selected');
    var photo = $(".selected-visit-container[data-index=" + index + "] .photo[data-id=" + this.selected_photos[index] + ']');
    if (photo[0]) {
      $(photo[0]).addClass('selected');
    } else {
      console.log("Photo", this.selected_photos[index], "On", index, "is missing!")
      this.selected_photos[index] = null;
    }

    if (this.selected_photos[0] && this.selected_photos[1]) {
      $('.compare-controls').show();
    } else {
      $('.compare-controls').hide();
    }
  }

  setup_measurements_graph() {
    var series = [{
      yAxis: 0,
      name: window.measurements_names['weight'],
      data: window.measurements_data['weight']
    }];
    for (var key in window.measurements_data) {
      if (key === 'weight') {
        continue;
      }
      series.push({
        yAxis: 1,
        name: window.measurements_names[key],
        data: window.measurements_data[key],
      });
    }

    this.setup_graph('shoulders');
    this.setup_graph('biceps', true);
    this.setup_graph('chest');
    this.setup_graph('waist');
    this.setup_graph('belly');
    this.setup_graph('hips');
    this.setup_graph('upper_leg', true);
    this.setup_graph('lower_leg', true);
    this.setup_graph('knee', true);
    this.setup_graph('calf', true);
    this.setup_graph('estimated_fm', false, 'Percentuale (%)');
    this.setup_graph('estimated_ffm', false, 'Percentuale (%)');

    try {
      Highcharts.chart('measurements-graph-weight', {
        title: null,
        chart: {
          type: "line",
        },
        xAxis: {
          title: {
            text: "Giorni",
          },
          categories: window.dates,
        },
        yAxis: {
          title: {text: "Misura (kg)"}
        },
        series: [{
          name: window.measurements_names['weight'],
          data: window.measurements_data['weight']
        }]
      })
    } catch(e) {
      console.error("Cannot find element #measurements-graph-weight")
    }
  }

  setup_graph(key, asym = false, yAxisTitle = 'Misura (cm)') {
    var series;
    if (asym) {
      series = [{
        name: window.measurements_names[key + '_dx'],
        data: window.measurements_data[key + '_dx']
      }, {
        name: window.measurements_names[key + '_sx'],
        data: window.measurements_data[key + '_sx']
      }];
    } else {
      series = [{
        name: window.measurements_names[key],
        data: window.measurements_data[key]
      }];
    }

    try {
      Highcharts.chart(`measurements-graph-${key}`, {
        title: null,
        chart: {
          type: "line",
        },
        xAxis: {
          title: {
            text: "Giorni",
          },
          categories: window.dates,
        },
        yAxis: {
          title: {text: yAxisTitle}
        },
        series: series,
      })
    } catch(e) {
      console.error(`Cannot find element #measurements-graph-${key}`)
    }
  }
}

export default VisitsController;
