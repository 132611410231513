import Rails from "@rails/ujs"
// import Turbo from "@hotwired/turbo-rails"
// import $ from "jquery/dist/jquery"

// Controllers
import ApplicationController from '../controllers/application'
import MealsController from '../controllers/meals'
import MedicinesController from '../controllers/medicines'
import VisitsController from '../controllers/visits'
import WorkoutsController from '../controllers/workouts'

Rails.start()

const temp = 2

export default temp
export { temp }

const copyTextToClipboard = function() {
    /* Get the text field */
  var copyText = document.getElementById("shareable-link-text")

  /* Select the text field */
  copyText.select()
  copyText.setSelectionRange(0, 99999) /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy")
}

window.copyTextToClipboard = copyTextToClipboard

// window.addEventListener('load', () => {
//   $('.custom-datepicker-input').datetimepicker({
//     locale: 'it',
//     autoclose: true,
//     format: 'L',
//     todayBtn: true,
//   })

//   $('.custom-datetimepicker-input').datetimepicker({
//     locale: 'it',
//     autoclose: true,
//     todayBtn: true,
//     icons: {
//       time: "fa fa-clock",
//       date: "fa fa-calendar",
//       up: "fa fa-arrow-up",
//       down: "fa fa-arrow-down"
//     }
//   })
// })

$(document).ready(() => {
  Rails.refreshCSRFTokens()

  const class_list = [
    ApplicationController,
    VisitsController,
    MealsController,
    MedicinesController,
    WorkoutsController,
  ]

  for ( let clazz of class_list ) {
    (new clazz()).init()
  }
})
