export default class ApplicationController {
  init() {
    if ($('.wysiwyg-editor').length > 0) {
      $('.wysiwyg-editor').each(function(i, el) {
        this.setupEditor(el)
      }.bind(this))
    }
  }

  setupEditor(el) {
    var editor = new Quill(el, {
      theme: 'snow',
    })

    var form = $(el).closest('form')

    form.on('submit', function(evt) {
      evt.preventDefault()
      $(evt.target).find('input.hidden-description-field').val(editor.root.innerHTML)
      evt.target.submit()
    })
  }
}
