// import $ from 'jquery'

export default class Meals {
  init() {
    window.dish_modal_update_suggested_macronutrients = function(data) {
      //console.log("I'm here");
      var table = $("#new-dish-modal .reference-macronutrients");
      var ref_data = data['item'] || table.data('dish-values');
      table.data('dish-values', ref_data);

      var actual_qty = ~~$('#new-dish-modal #dish_quantity').val();
      //console.log("Quantity is", actual_qty);

      ['carbohydrates', 'proteins', 'fats', 'energy'].forEach(function(obj) {
        //console.log("Handling", obj);
        var ref = table.find('td.reference-' + obj);
        var sug = table.find('td.suggested-' + obj);
        var dish = table.find('td.dish-' + obj);
        var actual = table.find('td.actual-' + obj);
        var other = parseFloat(table.find('td.choice-' + obj).text()) || 0;
        var total = table.find('td.total-' + obj);
        var dishValue = ref_data.macronutrients['total_' + obj];
        var actualValue = parseFloat((dishValue * actual_qty / 100.0).toFixed(1));
        var suggValue = 0;

        if (parseFloat(dishValue) !== 0 && parseFloat(ref.text()) !== 0) {
          suggValue = Math.round((parseFloat(ref.text()) - other) / parseFloat(dishValue) * 100);
        }

        var unit = obj === 'energy' ? 'kcal' : 'g';

        sug.text(suggValue + ' g');
        dish.text(dishValue + ' ' + unit);
        actual.text(actualValue + ' ' + unit);
        total.text((actualValue + other).toFixed(1) + ' ' + unit);
      });
    }
  }
}
